import React, {useState, useEffect} from 'react'

import Image from 'next/image'

//redux
import { useAppSelector } from "../hooks/useSelector";

export interface CoverPhotoLessonProps {
    height?: string;
    textSize?: string;
    image?: File | null | string;
    alt?: string;
    className?: string;
    isRound?: boolean;
    shortPlaceholder?: boolean;
}

export const CoverPhotoLesson = ({
    height='h-[48px]',
    textSize='text-md',
    alt='Image',
    image=null,
    className,
    isRound=false,
    shortPlaceholder=false
}:CoverPhotoLessonProps) => {
    /**
     * PLACEHOLDER TEXT
     */
    const placeholder = shortPlaceholder ? 'A' : 'AMBITION'

    
    /**
     * IMAGE & IMAGE URL
    */
    // const [isPortrait, setIsPortrait] = useState<boolean | null>(null)
    const [imageUrl, setImageUrl] = useState('')
    useEffect(() => {
        if (image && typeof image!=='string') {
            setImageUrl(URL.createObjectURL(image))
        } else {
            setImageUrl(image ? image : '')
        }
    },[image])


    return (
        <div
            className={`
                ${height} 
                ${isRound ? 'aspect-square' : 'aspect-video'}
                 flex items-center justify-center w-full h-full
            `}
        >
            <div 
                style={{width: '100%', height: '100%', position: 'relative'}}
                className={`
                    flex items-center justify-center bg-gray-300 
                    overflow-hidden
                    ${isRound ? 'rounded-full' : ''}
                    ${className}
            `}>
                {   (imageUrl) ? (
                    <Image
                        src={typeof image=='string' ? `${image}` : imageUrl} 
                        alt={alt}
                        style={{ objectFit: 'cover' }}
                        fill
                        sizes="(max-width: 768px) 100vw, 
                            (max-width: 1200px) 50vw, 
                            33vw"
                        priority
                        onError={() => {
                            console.error('error reading image url, setting to none');
                            setImageUrl('');
                        }}
                    />
                ) : (
                    <div className='flex flex-col gap-0'>
                        <div className={`flex flex-col relative items-center justify-center`}>
                            <p 
                                className={`${textSize}`}
                                style={{ fontFamily: 'G-Knewave' }}
                                >{placeholder}
                            </p>
                            <p 
                                className={`${textSize} -translate-y-0.5 -translate-x-0.5 absolute text-gray-400`}
                                style={{ fontFamily: 'G-Knewave' }}
                                >{placeholder}
                            </p>
                        </div>
                        <p>NO IMAGE</p>
                    </div>
                )}
            </div>
        </div>
    )
}