import React, {useState, useEffect} from 'react'
import { BouncingLoader } from '../../atoms-new/loader/BouncingLoader';

interface ButtonProps {
  /* color variations */
  /* Button size */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  /* background fill or outline */
  bgType? : 'fill' | 'outline' | 'none';
  /* corners of button */
  borderRadius? : 'square' | 'curved' | 'rounded';
  isSubmit?:boolean;
  /* disabled */
  disabled? : boolean;
  loading?: boolean;
  /* extra classnames */
  className? : string;
  /* children are string or jsx element */
  children: string | JSX.Element[] | JSX.Element;
  /* Optional click handler */
  onClick?: () => void;
}

export const Button = ({
  size = 'md', 
  bgType = 'fill', 
  borderRadius = 'curved', 
  disabled = false, 
  loading = false,
  isSubmit = false,
  className,
  children = 'button',
  onClick,
  ...props
}: ButtonProps) => {

  // Button State Variables
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);


  /** Default Styles */
  var defaultStyle = {}
  /** BLACK */
  if (bgType==='outline') { defaultStyle = {
    backgroundColor: "#FFFFFF", // bg-white
    color: '#1F2937', //text-gray-700
    border: "1px solid #1F2937" // border-gray-700
  }} else if (bgType==='none') { defaultStyle = {
    backgroundColor: "transparent", // bg-white
    color: '#1F2937', //text-gray-700
    fontWeight: '600', //font-semibold
  }} else { defaultStyle = {
    //black solid
    backgroundColor: "#1F2937", // bg-gray-700
    color: '#FFFFFF' //text-white
  }}

  /** Hover Styles */
  var hoverStyle = {}
  /** GRAY */
  if (bgType==='outline') {hoverStyle = {
    backgroundColor: "#E5E7EB", // bg-gray-200
  }} else if (bgType==='none') {hoverStyle = {
    backgroundColor: "#FFFFFF", // bg-white
    textDecoration: 'underline',  //underline
  }} else {  hoverStyle = {
    //black
    backgroundColor: "#111827", // bg-gray-800
  }}

  /** DISABLED STYLE */
  var disabledStyle = {}
  
  /** BLACK */
  if (bgType==='fill') { disabledStyle = {
    backgroundColor: "#9CA3AF", // bg-gray-400
    color: "#E5E7EB" // bg-gray-200
  }} else if (bgType==='outline') { disabledStyle = {
    color: "#9CA3AF", // bg-gray-400
    border: "1px solid #9CA3AF", //border-gray-400
  }} else if (bgType==='none') { disabledStyle = {
    color: "#9CA3AF", // bg-gray-400
  }}


  // Combine styles based on hover and active status
  const combinedStyles = {
    cursor: disabled ? 'auto' : 'pointer',
    ...defaultStyle,
    ...(isHovered && hoverStyle),
    //...(isActive && activeStyle),
    ...(disabled && disabledStyle)
  };


  /** Corners */
  const [corner, setCorner] = useState('rounded-md')
  useEffect(() => {
    if (borderRadius=='square') {
      setCorner('rounded-none')
    } else if (borderRadius==='curved') {
      setCorner('rounded-md')
    } else if (borderRadius==='rounded') {
      setCorner('rounded-full')
    }
  },[borderRadius])

  /** SIZE */
  const [buttonSize, setButtonSize] = useState('px-5 py-2.5 text-sm font-medium')
  useEffect(() => {
    if (size==='xs') {
      if (bgType!=='none') {
        setButtonSize('px-3 py-2 text-xs font-medium')
      } else {
        setButtonSize('px-0 py-0 text-xs font-medium')
      }
    } else if (size==='sm') {
      if (bgType!=='none') {
        setButtonSize('px-3 py-2 text-sm font-medium')
      } else {
        setButtonSize('px-0 py-0 text-sm font-medium')
      }
      
    } else if (size==='md') {
      if (bgType!=='none') {
        setButtonSize('px-5 py-2.5 text-sm font-medium min-h-[44px]')
      } else {
        setButtonSize('px-0 py-0 text-sm font-medium')
      }
      
    } else if (size==='lg') {
      if (bgType!=='none') {
        setButtonSize('px-5 py-3 text-base font-medium')
      } else {
        setButtonSize('px-0 py-0 text-base font-medium')
      }
      
    } else if (size==='xl') {
      if (bgType!=='none') {
        setButtonSize('px-6 py-3.5 text-base font-medium')
      } else {
        setButtonSize('px-0 py-0 text-base font-medium')
      }

    }
  },[size, bgType])



  /** Click handling */
  const clickHandler = () => {
    setIsActive(!isActive)
    if (onClick) {
      onClick();
    }
  }


  return (
    <button
      type={isSubmit ? 'submit' : 'button'}
      className={`flex items-center justify-center cursor-pointer
        ${buttonSize}
        ${corner} 
        ${className}
      `}
      style={combinedStyles}
      disabled={disabled || loading}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={clickHandler}
      {...props}
    >
      {loading ? (
        <BouncingLoader className='mt-3'/>
      ) : (
        children
      )}
    </button>
  )
}
