import React from 'react';

interface BasicInputProps {
    type?: 'text' | 'password' | 'email' | 'number';
    id?: string;
    title?: string;
    errorMessage?: string;
    value?: string | number | undefined;
    onChange: (value:string | number | undefined) => void;
    disabled?: boolean;
    successShow?: boolean;
    placeholder?: string;

    className?: string;
    titleClassName?: string;
    inputClassName?: string;
    onBlur?: () => void;
    onFocus?: () => void;
}

export const BasicInput = ({
    type='text',
    id,
    title,
    errorMessage,
    value,
    onChange,
    disabled=false,
    successShow=true,
    placeholder,
    
    className, 
    titleClassName,
    inputClassName,
    onBlur,
    onFocus,

}: BasicInputProps) => {

    const borderColor = 
        errorMessage ? 'border-red-500 border-2' : 
        (!errorMessage && value && (type=='email' || type=='password' ) && (successShow)) ? 'border-green-500 border-2' : 
        'border-gray-300'

    return (
        <div className={`flex flex-col ${className}`}>
            {title && (
                <p
                className={`
                    ml-2 text-gray-700 text-md
                    ${titleClassName}
                `}
                >
                {title}
                </p>
            )}

            <input 
                id = {id ? id : title ? title : undefined}
                name = {title}
                type = {type}
                placeholder={placeholder ? placeholder : undefined}

                value = {value ? value : ''}
                onChange = {(e) => onChange(e.target.value)}
                disabled={disabled}
                onBlur={onBlur}
                onFocus={onFocus}
        
                className={`
                    border ${borderColor}
                    placeholder-gray-400
                    py-2 px-3 bg-gray-50
                    rounded ${inputClassName}
                    ${disabled ? 'text-gray-400' : 'text-gray-900'}
                `}
            />

            <p 
                className={`text-xs ml-2 text-red-500 ${errorMessage ? '' : 'hidden'}`}
                >{errorMessage}
            </p>
        </div>
    )
}
