import React, { useState } from 'react';
import Link from 'next/link';

//components
import { HeaderLogo } from '@/src/atoms/logo/headerLogo';

//redux
import { useAppSelector } from '@/src/hooks/useSelector';
import { useAppDispatch } from '@/src/hooks/useDispatch';
import { setLanguage, updateLanguage } from '@/store/slices/settingsSlice';

interface UserFooterProps {}

export const UserFooterNew = ({}: UserFooterProps) => {
    const dispatch = useAppDispatch();

    // // Initialize the language state with useState
    // const [language, setLanguage] = useState<'en' | 'jp'>('en');

    //language translation settings
    const l = useAppSelector(state => state.settings).data.language

    //user data
    const userData = useAppSelector(state => state.user).data
    

    const menuTitleText = 'text-[18px] md:text-[20px]';
    const menuItemText = 'text-[14px] md:text-[18px]';

    /**
     * TEXT TRANSLATIONS
    */
    const text: any = {
        home: {
            en: 'Home',
            jp: 'ホーム'
        },
        about: {
            en: 'About Ambition',
            jp: 'Ambitionとは'
        },
        contact: {
            en: 'Contact us',
            jp: '問い合わせ'
        },
        services: {
            en: 'Services',
            jp: 'サービス'
        },
        lessons: {
            en: 'Lessons',
            jp: 'レッスン'
        },
        organizers: {
            en: 'Organizers',
            jp: '主催者'
        },
        privacy_policy: {
            en: 'Privacy Policy',
            jp: '個人情報保護方針'
        },
        terms_conditions: {
            en: 'Terms & Conditions',
            jp: '使用規約'
        },
    };

    const toggleLanguage = (lang: 'en' | 'jp') => {
        if (lang !== l) {
            //check if user logged or not
            let loggedIn = false
            if ('user' in userData && 'token' in userData.user) {
              loggedIn = true
            }
            // logged in: set to user settings in backend data
            if (loggedIn) {
              dispatch(updateLanguage({language: lang}))
            // not logged in: only set state in redux
            } else {
              dispatch(setLanguage(lang))
            }
            
          }

        setLanguage(lang);
    };

    return (
        <div className='flex flex-col text-center items-center md:text-left bg-gray-700 text-white '>
            <div className='flex flex-col'>
                <div className='flex flex-col md:flex-row md:justify-between md:pt-10 md:px-11 lg:pt-11 lg:px-20'>
                    <div className=''>
                        <div className='mb-3 md:mb-10'>
                            <div className='w-fit mx-auto md:mx-0 mt-4 md:mt-0'>
                                <Link href='/'>
                                    <HeaderLogo />
                                </Link>
                            </div>
                            <p className='hidden md:inline-block font-bold pt-2'>
                                Each One Teach One
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-col justify-between font-extralight'>
                        <div className='flex flex-col mb-5 md:flex-row md:gap-28 justify-between w-full'>
                            <div className='flex flex-col mb-3 gap-3 md:mb-0 w-full whitespace-nowrap'>
                                <Link href='/about'>
                                    <p className={`${menuItemText}`}>{text['about'][l]}</p>
                                </Link>
                                <Link href='/contact'>
                                    <p className={`${menuItemText}`}>{text['contact'][l]}</p>
                                </Link>
                            </div>
                            <div className='flex flex-col gap-3 w-full whitespace-nowrap'>
                                <Link href='/search-lessons'>
                                    <p className={`${menuItemText}`}>{text['lessons'][l]}</p>
                                </Link>
                                <Link href='/search-organizers'>
                                    <p className={`${menuItemText}`}>{text['organizers'][l]}</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bg-gray-900 md:bg-transparent h-full w-screen md:pb-10 md:px-11 lg:pb-11 lg:px-20'>
                    <div className='flex flex-col md:flex-row-reverse md:justify-between'>
                        <div className='flex flex-row mt-4 px-10 gap-5 md:gap-10 text-gray-400 md:justify-between justify-center md:justify-end'>
                            <p 
                                onClick={() => toggleLanguage('en')}
                                className={l === 'en' ? 'font-bold text-white cursor-pointer' : 'cursor-pointer'}>
                                English
                            </p>
                            <p 
                                onClick={() => toggleLanguage('jp')}
                                className={l === 'jp' ? 'font-bold text-white cursor-pointer' : 'cursor-pointer'}>
                                日本語
                            </p>
                        </div>
                        {/* { ('user' in userData && 'token' in userData.user)  ? (
                            <></>
                        ) : (
                            <div>
                                <p className='font-bold mt-6'>
                                    Have an account? Sign in
                                </p>
                            </div>
                        )} */}
                        
                    </div>
                    <div className='flex flex-row pt-3 pb-5 md:pb-0 gap-5 md:gap-10 justify-center md:justify-start text-gray-400'>
                        <Link href='/privacy-policy'>
                            <p className='text-sm'>{text['privacy_policy'][l]}</p>
                        </Link>
                        <Link href='/terms'>
                            <p className='text-sm'>{text['terms_conditions'][l]}</p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};
