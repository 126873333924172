import { useEffect, useState } from 'react';
import axios from 'axios'

import { BasicInput } from "@/src/form-v2/input-form/BasicInput"
import { Button } from '@/src/atoms/button/Button2';

//helper
import { validateEmail } from '@/src/helpers/validation/validation';

import { useAppSelector } from '@/src/hooks/useSelector';

//type
import { AlertType } from '@/src/atoms/alert';

interface SubscribePlaceholderProps {
    alreadySubscribedHandler?: () => void;
    successHandler?: () => void;
    userExistsHandler?: () => void;
    children?: JSX.Element | JSX.Element[];
}

export const SubscribePlaceholder = ({
    alreadySubscribedHandler, 
    successHandler,
    userExistsHandler,
    children
}:SubscribePlaceholderProps) => {

    //language translation
    const l = useAppSelector(state => state.settings).data.language

    /** EMAIL SUBSCRIPTION */
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const submitEmail = async(e: React.SyntheticEvent) => {
        e.preventDefault();
        if (!email) { 
            setEmailError('required')
            return;
        }
        if (!validateEmail(email)) {
            setEmailError('Not a valid email')
            return;
        }

        //axios call to submit email
        const config = { headers: {
            'Content-Type': 'Application/json',
        }}
        //get env var
        const res = await fetch('/api/env/backend-url')
        const {backendUrl} = await res.json();
        if (!backendUrl) {
            console.error('Backend URL env is missing');
            return 
        }

        axios.post(
            `${backendUrl}/api/contact/submit-email-subscription/`,
            {email:email},
            config
        )
            .then(response => {
                console.log('response')
                console.log(response)
                const {status} = response
                if (status==226) {
                    if (alreadySubscribedHandler) { alreadySubscribedHandler() }
                } else if (status==200) {
                    if (successHandler) { successHandler() }
                    setEmail('')
                }
            })
            .catch(error => {
                const {request} = error
                const {status} = request
                if (status==409) {
                    if (userExistsHandler) { userExistsHandler() }
                }
            })
    }

    /**
     * TEXT TRANSLATIONS
    */
    const text:any = {
        button_text:{
            en:'Subscribe Now',
            jp:'今すぐ登録'
        },
        email: {
            en:'Email',
            jp:'メール'
        },

    }


    return (
        <>
            {children}

            <form 
                className='flex flex-col md:flex-row gap-2 items-start'
                onSubmit={(e) => submitEmail(e)}
            >
                <BasicInput 
                    value = {email}
                    onChange={(value) => setEmail(value as string)}
                    placeholder={text['email'][l]}
                    className='min-w-[300px]'
                    errorMessage={emailError}
                />
                <Button
                    isSubmit={true}
                    >{text['button_text'][l]}
                </Button>
            </form>
        </>

    )
}